import axios from 'axios';

// Set the base URL globally
// ideal should be add this in .env file and read from there local env
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:3000' 
}


// Add a request interceptor
axios.interceptors.request.use(
  config => {
    if (!(config.url.includes('login') || config.url.includes('oauth'))) {
      const isvtagPresent = localStorage.getItem("vtag");
      const username = localStorage.getItem("userName");
      if (!(isvtagPresent == undefined || isvtagPresent == "" || isvtagPresent == null)) {
        config.headers['Authorization'] = isvtagPresent;
        config.headers['username'] = username;
      }
    }
    const brandCountry = localStorage.getItem("brandCountry");
    if (!(brandCountry == undefined || brandCountry == "" || brandCountry == null)) {
      config.headers['brandCountry'] = brandCountry;
    } else {
      config.headers['brandCountry'] = undefined;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !error.config.url.includes('login')) {
      if (error.response.data === "Unauthorized") {
        localStorage.clear();
        window.location.href = "/Logoff";
      }
    }
    return Promise.reject(error);
  }
);

export default axios;